* {
    margin: 0;
    padding: 0;
    font-family: "DM Sans", sans-serif;
    text-decoration: none;
    color: white;
    text-align: center;
}

body {
    background: #404040;
}

.links {
    color:#7bf4ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    text-decoration: underline;
}