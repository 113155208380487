.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap: 10px;
    background: #666666;
}

.navlinks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}