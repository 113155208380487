.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
  min-height: 50vh;
  padding: 20px;
  gap: 5vh;
  flex-direction: column;
}

.body {
  width: auto;
  max-width: 900px;
  height: auto;
  background: #666666;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 40px;
  object-fit: cover;
}

.body-text {
  font-size: large;
  text-align: left;
}

.lists li{
  font-size: large;
  text-align: left;
  justify-content: left;
  align-items: left;
}

@media (max-width: 600px) {
  .home-container {
    padding: 10px;
  }

  .body {
    padding: 20px;
  }

  .responsive-image {
    max-width: 100%;
  }
}