.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
    min-height: 50vh;
    padding: 20px;
    gap: 5vh;
    flex-direction: column;
  }

.body {
    max-width: 900px;
    height: auto;
    background: #666666;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 40px;
    object-fit: cover;
}

.body-text {
    font-size: large;
    text-align: left;
}

.email-link {
    color:#7bf4ff;
    text-decoration: underline;
    text-align: left;
}

.responsive-image {
    width: 100%; /* Makes the image take up the full width of its container */
    height: auto; /* Maintains the aspect ratio of the image */
    display: block; /* Removes any extra space below the image */
}

@media (max-width: 600px) {
    .home-container {
      padding: 10px;
    }
  
    .body {
      padding: 20px;
    }
  
    .responsive-image {
      max-width: 100%;
    }
  }